import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commheader from "./header";
import Commfooter from "./footer";

import youngwomanworking from "../../assets/images/young-woman-working-office-with-laptop-headphones-white-wall-customer-service-call-center-removebg-preview.png";
import nounworkexperience1937322 from "../../assets/images/noun-work-experience-1937322.png";
import nounbank2303716 from "../../assets/images/noun-bank-2303716.png";
import nountime5116075 from "../../assets/images/noun-time-5116075.png";
import nounclient4716587 from "../../assets/images/noun-client-4716587.png";

const contactus = () => {
    const [FullName, setFullName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [Subject, setSubject] = useState("");
    const [Message, setMessage] = useState("");

    const [CEmailAddress, setCEmailAddress] = useState("");
    const [CContactNumber, setCContactNumber] = useState("");
    const [CAddress, setCAddress] = useState("");

    const [FrequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([]);

    useEffect(() => {
        getContactInformation();
        getFrequentlyAskedQuestions();
    }, []);

    const getContactInformation = async () => {
        axios.post(global.APIURL + "/ContactInformationDetail")
            .then(Dres => {
                var CIArr = Dres.data;
                if (CIArr != '') {
                    setCEmailAddress(CIArr[0].CEmailAddress);
                    setCContactNumber(CIArr[0].CContactNumber);
                    setCAddress(CIArr[0].CAddress);
                }
                else {
                    setCEmailAddress('');
                    setCContactNumber('');
                    setCAddress('');
                }
            });
    };

    const getFrequentlyAskedQuestions = async () => {
        axios.post(global.APIURL + "/FrequentlyAskedQuestionsWeb")
            .then(Dres => {
                setFrequentlyAskedQuestions(Dres.data);
            });
    };

    const SaveContactInqiry = async () => {
        let IsInsert = 1;
        if (FullName == '') {
            IsInsert = 0;
            alert("Please input fullname.");
            return;
        }
        if (EmailAddress == '') {
            IsInsert = 0;
            alert("Please input email address.");
            return;
        }
        if (Subject == '') {
            IsInsert = 0;
            alert("Please input subject.");
            return;
        }
        if (Message == '') {
            IsInsert = 0;
            alert("Please input message.");
            return;
        }
        if (IsInsert == 1) {
            const ContactInquiry = {
                FullName: FullName,
                EmailAddress: EmailAddress,
                ContactInquirySubject: Subject,
                ContactInquiryMessage: Message
            };
            console.log(ContactInquiry);
            axios.post(global.APIURL + "/ContactInquirySave", ContactInquiry)
                .then(res => {
                    setFullName('');
                    setEmailAddress('');
                    setSubject('');
                    setMessage('');
                    $("#txtFullName").val('');
                    $("#txtEmail").val('');
                    $("#txtSubject").val('');
                    $("#txtMessage").val('');
                    alert("Contact inquiry save successfully.");
                });
        }
    };
    return (
        <React.Fragment>
            <Commheader />
            <section className="banner-service-detilas">
                <div className="container">
                    <div className="banner-service-content">
                        <h1>We would love to help you!</h1>
                        <p>We are always there to assist you with your credit score,<br/> credit report, CMR Rank, company report, or any other credit needs.</p>
                    </div>
                </div>
            </section>

            <section className="gettouch-withus">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div>
                                <div className="gettouch-withus-content">
                                    <h2>Get in Touch with Us</h2>
                                    <p>Want to get in touch? We did love to hear from you. Here how you can reach us...</p>
                                    <div>
                                        <div className="d-flex align-items-baseline">
                                            <i className="fas fa-map-marker-alt mapmarker newgetusicon"></i>
                                            <a href="" className="gettouch-withus-addres">{CAddress}</a>
                                        </div>
                                        <div className="d-flex align-items-baseline">
                                            <i className="fas fa-envelope mapmarker newgetusicon "></i>
                                            <a href="" className="gettouch-withus-addres">{CEmailAddress}</a>
                                        </div>
                                        <div className="d-flex align-items-baseline">
                                            <i className="fas fa-phone mapmarker newgetusicon"></i>
                                            <a href="" className="gettouch-withus-addres">{CContactNumber}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="young-womniamges">
                                    <img src={youngwomanworking} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 newformad">
                            <div className=" row ">
                                <div className="col-md-6">
                                    <div className="newcontactformadd">
                                        <label className="form-label">Full Name</label>
                                        <input type="email" className="form-control" id="txtFullName" placeholder="Write your name" onChange={e => setFullName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="newcontactformadd typebox">
                                        <label className="form-label">Email</label>
                                        <input type="email" className="form-control" id="txtEmail" placeholder="Write your Email" onChange={e => setEmailAddress(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="newcontactformadd mt-3">
                                <label className="form-label">Subject</label>
                                <input type="email" className="form-control" id="txtSubject" placeholder="Write Your Subject" onChange={e => setSubject(e.target.value)} />
                            </div>
                            <div className="newcontactformadd mt-3">
                                <label className="form-label">Message </label>
                                <textarea className="form-control" id="txtMessage" rows="3" placeholder="Write Your Message here" onChange={e => setMessage(e.target.value)}></textarea>
                            </div>
                            <p className="newconsentadd">By submitting this form you consent to us emailing you occasionally about our products and
                                services. You can unsubscribe from emails at any time, and we will never pass your email onto
                                third parties. Privacy Policy</p>
                            <div className="submitingadd">
                                <button type="button" className="btn btn-primary contactussubmitbtnadd" onClick={() => SaveContactInqiry()}>Submit</button>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section className="frequently">
                <div className="container">
                    <div className="frequently-content">
                        <h2>Frequently asked questions</h2>
                    </div>
                    <div className="accordion" id="accordionExample">
                        {FrequentlyAskedQuestions.map((item, idx) => (
                            idx == 0 ?
                                <div className="accordion-item newaccorsionfrequentlyadd mt-4" key={idx}>
                                    <h2 className="accordion-header" id={item.headingOneId1}>
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={item.collapseOneId2} aria-expanded="true" aria-controls={item.collapseOneId1}>
                                            {item.Title}
                                        </button>
                                    </h2>
                                    <div id={item.collapseOneId1} className="accordion-collapse collapse show" aria-labelledby={item.headingOneId1} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="accordion-item newaccorsionfrequentlyadd mt-4" key={idx}>
                                    <h2 className="accordion-header" id={item.headingOneId1}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={item.collapseOneId2} aria-expanded="false" aria-controls={item.collapseOneId1}>
                                            {item.Title}
                                        </button>
                                    </h2>
                                    <div id={item.collapseOneId1} className="accordion-collapse collapse" aria-labelledby={item.headingOneId1} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                                        </div>
                                    </div>
                                </div>

                        ))}

                    </div>
                </div>

            </section>

            <section className="your-exoerience">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="your-exoerience-boxcontent">
                                <img src={nounworkexperience1937322} alt="" />
                                <h3>20+</h3>
                                <p>Years Experience</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="your-exoerience-boxcontent">
                                <img src={nounbank2303716} alt="" />
                                <h3>90+</h3>
                                <p>Associated Banks</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="your-exoerience-boxcontent">
                                <img src={nountime5116075} alt="" />
                                <h3>50,000+</h3>
                                <p>Hours of Training</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="your-exoerience-boxcontent">
                                <img src={nounclient4716587} alt="" />
                                <h3>1,00,000 + </h3>
                                <p>Satisfied Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Commfooter />
        </React.Fragment>
    )

}

export default contactus
