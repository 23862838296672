import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commheader from "./header";
import Commfooter from "./footer";

import guranteedimg from "../../assets/images/guranteedimg.png";
import nounquoteguranteed from "../../assets/images/noun-quote-guranteed.png";
import remquo from "../../assets/images/remquo.png";
import MaskGroup17 from "../../assets/images/Mask Group 17.png";
import ready from "../../assets/images/ready.png";
import MaskGroup20 from "../../assets/images/Mask Group 20.png";
import getintouch from "../../assets/images/getintouch.png";

const servicedetails = () => {
    const { addToast } = useToasts();
    const [FrequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([]);
    const [ServiceDetail, setServiceDetail] = useState([]);

    useEffect(() => {
        let KCServiceId = parseInt(localStorage.getItem("KCServiceId") == null ? "0" : localStorage.getItem("KCServiceId") == "" ? "0" : localStorage.getItem("KCServiceId"));
        if (KCServiceId > 0) {
            getFrequentlyAskedQuestions();
            getServiceDetail();
        }
        else {
            window.location.href = "/service";
        }
    }, []);

    const getFrequentlyAskedQuestions = async () => {
        axios.post(global.APIURL + "/FrequentlyAskedQuestionsWeb")
            .then(Dres => {
                setFrequentlyAskedQuestions(Dres.data);
            });
    };

    const getServiceDetail = async () => {
        let KCServiceId = parseInt(localStorage.getItem("KCServiceId") == null ? "0" : localStorage.getItem("KCServiceId") == "" ? "0" : localStorage.getItem("KCServiceId"));
        const ContactInquiry = {
            WebServiceId: KCServiceId
        };
        axios.post(global.APIURL + "/WebServicesDetailListWeb", ContactInquiry)
            .then(Dres => {
                console.log(Dres.data);
                setServiceDetail(Dres.data);
            });
    };

    return (
        <React.Fragment>
            <Commheader />
            <section className="banner-service-detilas">
                <div className="container">
                    <div className="banner-service-content">
                        <h1>Improve Company CMR Rank</h1>
                        <p>After a 360-degree study by our Experienced Credit Expert, we assist you to<br />understand concerns in your CIBIL Report and help you enhance your CMR Rank.</p>

                    </div>
                </div>
            </section>
            {ServiceDetail.map((item, idx) => (
                item.NoType == 'O' ?
                    <section className="guranted-solution" key={idx}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-7 order-2 order-md-1">
                                    <div className="guranted-solution-content">
                                        <div dangerouslySetInnerHTML={{ __html: item.DetailTitle }} />
                                        <div dangerouslySetInnerHTML={{ __html: item.DetailDescription }} />
                                    </div>
                                </div>
                                <div className="col-md-5 order-1 order-md-2">
                                    <div className="guranted-solution-images">
                                        <img src={item.DetailImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="distinctio" key={idx}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="distinctio-images">
                                        <img src={item.DetailImage} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="distinctio-contentadd">
                                        <div dangerouslySetInnerHTML={{ __html: item.DetailTitle }} />
                                        <div dangerouslySetInnerHTML={{ __html: item.DetailDescription }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

            ))}

            <section className="advisers">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="advisers-iamges">
                                <div className="readyimagescontent">
                                    <h2>Our Advisors Are Ready<br /> To Help You</h2>
                                    <div className="readyimg">
                                        <img src={ready} alt="" />
                                    </div>
                                </div>
                                <img src={MaskGroup20} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="accordion accordion-flush newservicedetailsaccordianadd" id="accordionFlushExample">
                                {FrequentlyAskedQuestions.map((item, idx) => (
                                    <div className="accordion-item" key={idx}>
                                        <h2 className="accordion-header" id={item.headingOneId1}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={item.collapseOneId2} aria-expanded="false" aria-controls={item.collapseOneId1}>
                                                {item.Title}
                                            </button>
                                        </h2>
                                        <div id={item.collapseOneId1} className="accordion-collapse collapse" aria-labelledby={item.headingOneId1} data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body servicedetailsbody">
                                                <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            How can I borrow money ASAP?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body servicedetailsbody">
                                            <p>- You must be at least 18 years old</p>
                                            <p>- You must have permanent residence in United States</p>
                                            <p> - You are not registered in the RKI / Debtor Register (DBR)</p>
                                            <p>The offers you receive are preliminary offers, which are<br /> provided that the information you have entered, are correct.</p>

                                            <p>At the same time, you must sign the loan offer with NemID<br /> before the bank can pay out your loan.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            How can you reduce the cost of my loans?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body servicedetailsbody">
                                            <p>- You must be at least 18 years old</p>
                                            <p>- You must have permanent residence in United States</p>
                                            <p> - You are not registered in the RKI / Debtor Register (DBR)</p>
                                            <p>The offers you receive are preliminary offers, which are<br /> provided that the information you have entered, are correct.</p>

                                            <p>At the same time, you must sign the loan offer with NemID<br /> before the bank can pay out your loan.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingfor">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefor" aria-expanded="false" aria-controls="flush-collapsefor">
                                            What does it cost to use KC Finance?
                                        </button>
                                    </h2>
                                    <div id="flush-collapsefor" className="accordion-collapse collapse" aria-labelledby="flush-headingfor" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body servicedetailsbody">
                                            <p>- You must be at least 18 years old</p>
                                            <p>- You must have permanent residence in United States</p>
                                            <p> - You are not registered in the RKI / Debtor Register (DBR)</p>
                                            <p>The offers you receive are preliminary offers, which are<br /> provided that the information you have entered, are correct.</p>

                                            <p>At the same time, you must sign the loan offer with NemID<br /> before the bank can pay out your loan.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Commfooter />

        </React.Fragment>
    )

}

export default servicedetails
