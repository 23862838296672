import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commheader from "./header";
import Commfooter from "./footer";

const privacypolicy = () => {
    const { addToast } = useToasts();
    useEffect(() => { }, []);

    return (
        <React.Fragment>
            <Commheader />
            <Commfooter />
        </React.Fragment>
    )
}

export default privacypolicy
