import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commheader from "./header";
import Commfooter from "./footer";
import $ from 'jquery';

import nounsearc from "../../assets/images/noun-searc.png";
import provideline from "../../assets/images/provideline.png";
import img1 from "../../assets/images/img1.png";
import cmrrank from "../../assets/images/cmrrank.png";
import img2stay from "../../assets/images/img2stay.png";
import img3 from "../../assets/images/img3.png";
import img4 from "../../assets/images/img4.png";
import img5 from "../../assets/images/img5.png";
import associatnounqeat from "../../assets/images/associatnounqeat.png";
import eldrigde from "../../assets/images/eldrigde.png";
import partnerwith from "../../assets/images/partnerwith.png";
import Slider from "react-slick";
import ServiceInquiry from "./serviceinquiry";

// import Slider from "react-slick";

const service = () => {
    const { addToast } = useToasts();

    const [FullName, setFullName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [Subject, setSubject] = useState("");
    const [Message, setMessage] = useState("");

    const [CEmailAddress, setCEmailAddress] = useState("");
    const [CContactNumber, setCContactNumber] = useState("");
    const [CAddress, setCAddress] = useState("");

    const [TestimonialList, setTestimonialList] = useState([]);

    const [WebServicesList, setWebServicesList] = useState([]);

    useEffect(() => {
        getContactInformation();
        getTestimonialListWeb();
        getWebServicesList();
    }, []);

    const getContactInformation = async () => {
        axios.post(global.APIURL + "/ContactInformationDetail")
            .then(Dres => {
                var CIArr = Dres.data;
                if (CIArr != '') {
                    setCEmailAddress(CIArr[0].CEmailAddress);
                    setCContactNumber(CIArr[0].CContactNumber);
                    setCAddress(CIArr[0].CAddress);
                }
                else {
                    setCEmailAddress('');
                    setCContactNumber('');
                    setCAddress('');
                }
            });
    };

    const SaveContactInqiry = async () => {
        let IsInsert = 1;
        if (FullName == '') {
            IsInsert = 0;
            alert("Please input fullname.");
            return;
        }
        if (EmailAddress == '') {
            IsInsert = 0;
            alert("Please input email address.");
            return;
        }
        if (Subject == '') {
            IsInsert = 0;
            alert("Please input subject.");
            return;
        }
        if (Message == '') {
            IsInsert = 0;
            alert("Please input message.");
            return;
        }
        if (IsInsert == 1) {
            const ContactInquiry = {
                FullName: FullName,
                EmailAddress: EmailAddress,
                ContactInquirySubject: Subject,
                ContactInquiryMessage: Message
            };
            console.log(ContactInquiry);
            axios.post(global.APIURL + "/ContactInquirySave", ContactInquiry)
                .then(res => {
                    setFullName('');
                    setEmailAddress('');
                    setSubject('');
                    setMessage('');
                    $("#txtFullName").val('');
                    $("#txtEmail").val('');
                    $("#txtSubject").val('');
                    $("#txtMessage").val('');
                    alert("Contact inquiry save successfully.");
                });
        }
    };

    const getTestimonialListWeb = async () => {
        const Testimonialitem = {
            Flag: 'A'
        };
        axios.post(global.APIURL + "/TestimonialList", Testimonialitem)
            .then(Dres => {
                setTestimonialList(Dres.data);
            });
    };

    const getWebServicesList = async () => {

        axios.post(global.APIURL + "/WebServicesListWeb")
            .then(Dres => {
                setWebServicesList(Dres.data);
            });
    };

    const ServiceDetailPageRedirect = async (ServiceId) => {
        localStorage.setItem("KCServiceId", ServiceId)
        window.location.href = "/servicedetails";
    };

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false
              
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            }
          ],
    };

    return (
        <React.Fragment>
            <Commheader />

            <section className="banner-service">
                <div className="container">
                    <div className="banner-service-content">
                        <h1>We provide what you desire</h1>
                        <p>We are a 360 Degree Credit Repair Company that helps you meet all your Finance-related requirements.</p>
                        <div className="service-input">
                            <input type="text" name="" placeholder="Looking for an service" />
                            <div>
                                <button type="button" className="btn btn-secondary newsubmitbtn newservicesearch">Search </button>
                            </div>
                            <div className="searchimages">
                                <img src={nounsearc} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="provide">
                <div className="container">
                    <div className="provide-content">
                        <div className="provideline">
                            <img src={provideline} alt="" />
                        </div>
                        <h2>Services We Provide</h2>
                        <p>We are financing solutions to the most critical challenges facing<br /> the developing world today.</p>
                    </div>
                    <div className="row">
                        {WebServicesList.map((item, idx) => (
                            <div className="col-md-4 mb-3" key={idx} >
                                <a onClick={() => ServiceDetailPageRedirect(item.Id)} style={{ textDecoration: 'none' }}>
                                    <div className="provideboxcontent">
                                        <img src={item.IconFile} alt="" />
                                        <h4>{item.Title}</h4>
                                        <p>{item.Description}</p>
                                    </div>
                                </a>
                            </div>
                        ))}

                    </div>
                </div>
            </section>


            <section className="testimonialassocaite">
                <div className="container">
                    <div className="srevices-content">
                        <div className="provideline">
                            <img src={provideline} alt="" />
                        </div>
                        <h2>Testimonial</h2>
                        <p>We are offering you as an ASSOCIATE PARTNER with us.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-8">
                           

                                <div className="col-md-12" >
                                    <div className="mainsliderassociat">
                                    <Slider {...settings}>
                                        {TestimonialList.map((item, idx) => (
                                           
                                                <div className="testimonailboxcontent associattestimonail" key={idx}>
                                                    <img src={associatnounqeat} alt="" className="newassociatimages" />
                                                    <div className="testimonialtextdot" dangerouslySetInnerHTML={{ __html: item.TestimonialMessage }} />
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src={item.TestimoniaPersonImage == '' ? eldrigde : item.TestimoniaPersonImage} alt="" className="eldridgeimages" />
                                                        <div className="eldridgeerdman">
                                                            <h5>{item.TestimonialName}</h5>
                                                            <p>{item.TestimonialDesignation}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                ))}
                                         
                                            </Slider>
                                        </div>
                                </div>


                        </div>
                        <div className="col-md-4">
                            <div className="parnerwithus">
                                <img src={partnerwith} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <ServiceInquiry/>
            <Commfooter />

        </React.Fragment>
    )

}

export default service
