import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import $ from 'jquery'; 

import LogoImage from "../../assets/images/KCFS LOGO.png";

const serviceinquiry = () => {
    const { addToast } = useToasts();

    const [FullName, setFullName] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [MobileNo, setMobileNo] = useState("");
    const [Subject, setSubject] = useState("");
    const [Message, setMessage] = useState("");

    const [CEmailAddress, setCEmailAddress] = useState("");
    const [CContactNumber, setCContactNumber] = useState("");
    const [CAddress, setCAddress] = useState("");

    useEffect(() => {
        getContactInformation();
    }, []);

    const getContactInformation = async () => {
        axios.post(global.APIURL + "/ContactInformationDetail")
            .then(Dres => {
                var CIArr = Dres.data;
                if (CIArr != '') {
                    setCEmailAddress(CIArr[0].CEmailAddress);
                    setCContactNumber(CIArr[0].CContactNumber);
                    setCAddress(CIArr[0].CAddress);
                }
                else {
                    setCEmailAddress('');
                    setCContactNumber('');
                    setCAddress('');
                }
            });
    };

    const SaveContactInqiry = async () => {
        let IsInsert = 1;
        if (FullName == '') {
            IsInsert = 0;
            alert("Please input fullname.");
            return;
        }
        if (EmailAddress == '') {
            IsInsert = 0;
            alert("Please input email address.");
            return;
        }
        if (MobileNo == '') {
            IsInsert = 0;
            alert("Please input mobile no..");
            return;
        }
        if (Subject == '') {
            IsInsert = 0;
            alert("Please input subject.");
            return;
        }
        if (Message == '') {
            IsInsert = 0;
            alert("Please input message.");
            return;
        }
        if (IsInsert == 1) {
            const ContactInquiry = {
                FullName: FullName,
                EmailAddress: EmailAddress,
                MobileNo: MobileNo,
                InquirySubject: Subject,
                InquiryMessage: Message
            };
            console.log(ContactInquiry);
            axios.post(global.APIURL + "/ServiceInquirySave", ContactInquiry)
                .then(res => {
                    setFullName('');
                    setMobileNo('');
                    setEmailAddress('');
                    setSubject('');
                    setMessage('');
                    $("#txtFullName").val('');
                    $("#txtMobileNo").val('');
                    $("#txtEmail").val('');
                    $("#txtSubject").val('');
                    $("#txtMessage").val('');
                    alert("Service inquiry save successfully.");
                });
        }
    };

    return (
        <React.Fragment>
             <section className="take-service">
                <div className="container newtakeserviceadd">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="takeserviceboxcontentquery">
                                <h2>Ready to improve your credit score?</h2>
                                <p>Give us a chance to show our expertise and let us improve your credit score.</p>
                                <h3>Here following or contact information</h3>
                                <ul className="footernavcontentadd">
                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-map-marker-alt mapmarker"></i>
                                        <li><a href="">{CAddress}</a></li>
                                    </div>
                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-envelope mapmarker"></i>
                                        <li><a href="">{CEmailAddress}</a></li>
                                    </div>
                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-phone mapmarker"></i>
                                        <li><a href="">{CContactNumber}</a></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="take-serviceform">
                                <div className="forminputtakeservice">
                                    <input type="text" id="txtFullName" placeholder="Name" onChange={e => setFullName(e.target.value)} />
                                </div>
                                <div className="forminputtakeservice">
                                    <input type="text" id="txtEmail" placeholder="Email" onChange={e => setEmailAddress(e.target.value)} />
                                </div>
                                <div className="forminputtakeservice">
                                    <input type="text" id="txtMobileNo" placeholder="Mobile No."  onChange={e => setMobileNo(e.target.value)}/>
                                </div>
                                <div className="forminputtakeservice">
                                    <input type="text" id="txtSubject" placeholder="Subject"  onChange={e => setSubject(e.target.value)}/>
                                </div>
                                <div className="forminputtakeservice formtextmessage">
                                    <textarea name="" id="txtMessage" placeholder="Message" onChange={e => setMessage(e.target.value)}></textarea>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-secondary takeservicesubmitbtn" onClick={() => SaveContactInqiry()}>Submit </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )

}

export default serviceinquiry
