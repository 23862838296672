import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import $ from 'jquery'; 

import LogoImage from "../../assets/images/KCFS LOGO.png";

const header = () => {
    const { addToast } = useToasts();

    const [HomeClass, setHomeClass] = useState("nav-link header-nav");
    const [AboutusClass, setAboutusClass] = useState("nav-link header-nav");
    const [ServiceClass, setServiceClass] = useState("nav-link header-nav");
    const [AchievementsClass, setAchievementsClass] = useState("nav-link header-nav");

    useEffect(() => { 
        var CurrentPage=window.location.pathname.replace('/','');
        if(CurrentPage=='home')
        {
            setHomeClass("nav-link active headeractivenav");
            setAboutusClass("nav-link header-nav");
            setServiceClass("nav-link header-nav");
            setAchievementsClass("nav-link header-nav");
        }
        else if(CurrentPage=='aboutus')
        {
            setHomeClass("nav-link header-nav");
            setAboutusClass("nav-link active headeractivenav");
            setServiceClass("nav-link header-nav");
            setAchievementsClass("nav-link header-nav");
        }
        else if(CurrentPage=='service')
        {
            setHomeClass("nav-link header-nav");
            setAboutusClass("nav-link header-nav");
            setServiceClass("nav-link active headeractivenav");
            setAchievementsClass("nav-link header-nav");
        }
        else if(CurrentPage=='servicedetails')
        {
            setHomeClass("nav-link header-nav");
            setAboutusClass("nav-link header-nav");
            setServiceClass("nav-link active headeractivenav");
            setAchievementsClass("nav-link header-nav");
        }
        else if(CurrentPage=='achivement')
        {
            setHomeClass("nav-link header-nav");
            setAboutusClass("nav-link header-nav");
            setServiceClass("nav-link header-nav");
            setAchievementsClass("nav-link active headeractivenav");
        }
        else if(CurrentPage=='contactus')
        {
            setHomeClass("nav-link header-nav");
            setAboutusClass("nav-link header-nav");
            setServiceClass("nav-link header-nav");
            setAchievementsClass("nav-link header-nav");
        }
    }, []);

    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-light bg-white header">
                <div className="container">
                    <div className="logo">
                        <a href="/"><img src={LogoImage} alt="" /></a>
                    </div>

                    <button className="navbar-toggler mainmenuopenbtn" type="button" >
                        &#9776;
                    </button>
                    <div id="mySidenav" className="sidenav ">
                        <div className="maincontentsideneww">
                            <div className="closebtn">
                                <div className="mainsidemenucloseedit">
                                    <div>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </div>

                                </div>

                            </div>
                            <a className={HomeClass} href="/" id="lnkHome">Home</a>
                            <a className={AboutusClass} href="aboutus" id="lnkAboutus">About Us</a>
                            <a className={ServiceClass} href="service" id="lnkService">Service</a>
                            {/* <a className={AchievementsClass} href="achivement" id="lnkAchievements">Achievements</a> */}
                            <div className="header-button-image">
                                <a href="#" className="p-0">
                                    <div className="plus mode"></div>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                            <li className="nav-item ">
                                <a className={HomeClass}  href="/" id="lnkHome1">Home</a>
                            </li>
                            <li className="nav-item headernavitems">
                                <a className={AboutusClass} href="aboutus" id="lnkAboutus1">About Us</a>
                            </li>
                            <li className="nav-item headernavitems">
                                <a className={ServiceClass} href="service" id="lnkService1">Service</a>
                            </li>
                            {/* <li className="nav-item headernavitems">
                                <a className={AchievementsClass} href="achivement" id="lnkAchievements1">Achievements</a>
                            </li> */}


                        </ul>
                        <form className="d-flex">
                            <a href="contactus" className="btn btn-outline-success newcontactbtn">Contact Us</a>
                        </form>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )

}

export default header
