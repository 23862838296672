import React from "react";
import { Redirect } from "react-router-dom";

import home from "../pages/frontend/home";
import service from "../pages/frontend/service";
import servicedetails from "../pages/frontend/servicedetails";
import aboutus from "../pages/frontend/aboutus";
import achivement from "../pages/frontend/achivement";
import contactus from "../pages/frontend/contactus";

import privacypolicy from "../pages/frontend/privacypolicy";
import termsCondition from "../pages/frontend/termsCondition";

const authProtectedRoutes = [
  { path: "/", exact: true, component: () => <Redirect to="/home" /> }
];

const publicRoutes = [
  { path: "/home", component: home},
  { path: "/service", component: service},
  { path: "/servicedetails", component: servicedetails},
  { path: "/aboutus", component: aboutus},
  { path: "/achivement", component: achivement},
  { path: "/contactus", component: contactus},
  { path: "/privacypolicy", component: privacypolicy},
  { path: "/termsCondition", component: termsCondition}
];                                        

export { authProtectedRoutes, publicRoutes };