import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {e.preventDefault();this.setState({ isDashboard: !this.state.isDashboard });}}to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                       <Link
                            to="/dashboard"
                            className="dropdown-item"
                          >
                            {this.props.t("Company/Employee Dashboard")}
                          </Link>
                      
                    </div>
                  </li>
                
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {e.preventDefault();this.setState({ isDashboard: !this.state.isDashboard });}}to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Retail")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      {/* <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Lead Management---add/edit Lead")}
                      </Link> */}
                      <Link to="/dashboard-crypto" className="dropdown-item">
                        {this.props.t("Client List")}
                      </Link>
                      <Link to="/kc37" className="dropdown-item">
                        {this.props.t("Lead List")}
                      </Link>
                      <Link to="/kc32" className="dropdown-item">
                        {this.props.t("Quick Inquiry")}
                      </Link>
                      <Link to="/kc33" className="dropdown-item">
                        {this.props.t("Quick Inquiry Form")}
                      </Link>
                      <Link to="/dashboard-blog" className="dropdown-item">
                        {this.props.t("To DO List")}
                      </Link>
                      {/* <Link to="/maps-leaflet" className="dropdown-item">
                            {this.props.t("Client List")}{" "}
                      </Link> */}
                      <Link to="/kc21" className="dropdown-item">
                            {this.props.t("Channel List")}{" "}
                      </Link>
                      <Link to="/kc23" className="dropdown-item">
                            {this.props.t("List Of Channel Partner")}{" "}
                      </Link>
                      <Link to="/kc22" className="dropdown-item">
                            {this.props.t("Followup List")}{" "}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {e.preventDefault();this.setState({ isDashboard: !this.state.isDashboard });}}to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Coorporate")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                        {/* <Link to="/maps-google" className="dropdown-item">
                            {this.props.t("Leads")}{" "}
                          </Link> */}
                          {/* <Link to="/maps-vector" className="dropdown-item">
                            {this.props.t("Lead Followups")}{" "}
                          </Link> */}
                           <Link
                            to="/kc-seven"
                            className="dropdown-item"
                          >
                            {this.props.t("Service List")}
                          </Link>
                          <Link
                            to="/kc-eight"
                            className="dropdown-item"
                          >
                            {this.props.t("Add Service")}
                          </Link>
                          <Link
                            to="/kc-nine"
                            className="dropdown-item"
                          >
                            {this.props.t("Support Ticket")}
                          </Link>
                          <Link
                            to="/kc-ten"
                            className="dropdown-item"
                          >
                            {this.props.t("Task")}
                          </Link>
                          {/* <Link
                            to="/kc-ele"
                            className="dropdown-item"
                          >
                            {this.props.t("Client Task")}
                          </Link>
                          <Link to="/calendar" className="dropdown-item">
                        {this.props.t("Client Meetings")}
                      </Link>
                      <Link to="/ui-dropdowns" className="dropdown-item">
                        {this.props.t("Service Detail")}
                      </Link> */}
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {e.preventDefault();this.setState({ isDashboard: !this.state.isDashboard });}}to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Accounts")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                       <Link to="/ecommerce-products" className="dropdown-item">
                            {this.props.t("Vendors")}
                          </Link>
                          <Link to="/ecommerce-orders" className="dropdown-item">
                            {this.props.t("Customer/Client Management ")}
                          </Link>
                          <Link to="/ecommerce-customers" className="dropdown-item">
                            {this.props.t("Vendor Invoices")}
                          </Link>
                          <Link to="/ecommerce-cart" className="dropdown-item">
                            {this.props.t("Customer Invoice")}
                          </Link>
                          <Link
                                to="/kc-twe"
                                className="dropdown-item"
                              >
                                {this.props.t("Profit & Loss Ledger")}
                              </Link>
                              <Link
                                to="/email-template-alert"
                                className="dropdown-item"
                              >
                                {this.props.t("GST Ledger(Monthly & Quarterly)")}
                              </Link>
                              <Link
                                to="/email-template-billing"
                                className="dropdown-item"
                              >
                                {this.props.t("GST 1/2a/2b/3b Report Generator")}
                              </Link>
                              <Link
                            to="/kc-fort"
                            className="dropdown-item"
                          >
                            {this.props.t("TDS Ledger (Yearly/Quarterly)")}
                          </Link>
                          <Link
                            to="/ui-alerts"
                            className="dropdown-item"
                          >
                            {this.props.t("TCS Ledger (Yearly/Quarterly")}
                          </Link>
                        
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-tone me-2" />
                      {this.props.t("HR")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                        { show: this.state.uiState }
                      )}
                    >
                      <Row>
                        <Col lg={4}>
                          <div>
                            <Link to="/ui-video" className="dropdown-item">
                              {this.props.t("Manage Salary Scale")}
                            </Link>
                            <Link to="/ui-general" className="dropdown-item">
                              {this.props.t("Manage Leave Type")}
                            </Link>
                            <Link to="/ui-rating" className="dropdown-item">
                              {this.props.t("Bulk Attendance")}
                            </Link>
                            <Link to="/ui-colors" className="dropdown-item">
                              {this.props.t("Manage Attendance")}
                            </Link>
                            <Link to="/ui-notifications" className="dropdown-item">
                              {this.props.t("Manage Time Change Request")}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <Link to="/ui-breadcrumb" className="dropdown-item">
                              {this.props.t("Shift Change")}
                            </Link>
                            <Link to="/ui-placeholders" className="dropdown-item">
                              {this.props.t("Generate Salary")}
                            </Link>
                            <Link to="/ui-sweet-alert" className="dropdown-item">
                              {this.props.t("Manage Salary Slips")}
                            </Link>
                            <Link to="/email-template-basic" className="dropdown-item">
                              {this.props.t("Manage Salary Register")}
                            </Link>
                            <Link
                              to="/email-read"
                              className="dropdown-item"
                            >
                              {this.props.t("Job Openings")}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                         
                            <Link to="/chat" className="dropdown-item">
                              {this.props.t("Job Application")}
                            </Link>
                            <Link to="/apps-filemanager" className="dropdown-item">
                              {this.props.t("CV Bank")}
                            </Link>
                            <Link to="/email-inbox" className="dropdown-item">
                              {this.props.t("Holiday Master")}
                            </Link>
                            <Link to="/kc-thr" className="dropdown-item">
                              {this.props.t("Holiday List")}
                            </Link>
                            {/* <Link to="/ui-buttons" className="dropdown-item">
                            {this.props.t("HR Dashboard")}
                          </Link> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  
               
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {e.preventDefault();this.setState({ isDashboard: !this.state.isDashboard });}}to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Utility")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/invoices-detail" className="dropdown-item">
                            {this.props.t("Utility")}
                          </Link>
                      
                    </div>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({
                          componentState: !this.state.componentState,
                        });
                      }}
                    >
                      <i className="bx bx-collection me-2" />
                      {this.props.t("Master")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.componentState,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ formState: !this.state.formState });
                          }}
                        >
                          {this.props.t("General Setting")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.formState,
                          })}
                        >
                          <Link to="/form-elements" className="dropdown-item">
                            {this.props.t("Country")}
                          </Link>
                          <Link to="/form-layouts" className="dropdown-item">
                            {this.props.t("State")}
                          </Link>
                          <Link to="/form-validation" className="dropdown-item">
                            {this.props.t("District")}
                          </Link>
                          <Link to="/form-advanced" className="dropdown-item">
                            {this.props.t("City")}
                          </Link>
                          <Link to="/form-editors" className="dropdown-item">
                            {this.props.t("Application Access Master")}
                          </Link>
                          <Link to="/form-uploads" className="dropdown-item">
                            {this.props.t("User Type")}{" "}
                          </Link>
                          <Link to="/form-xeditable" className="dropdown-item">
                            {this.props.t("Company Master")}
                          </Link>
                          <Link to="/kc24" className="dropdown-item">
                            {this.props.t("Branch Master")}
                          </Link>
                          
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              tableState: !this.state.tableState,
                            });
                          }}
                        >
                          {this.props.t("HR Settings")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu hr-menu", {
                            show: this.state.tableState,
                          })}
                        >
                          <Link to="/tables-basic" className="dropdown-item">
                            {this.props.t("Relations")}
                          </Link>
                          <Link to="/tables-datatable" className="dropdown-item">
                            {this.props.t("Qualification")}
                          </Link>
                          <Link
                            to="/tables-responsive"
                            className="dropdown-item"
                          >
                            {this.props.t("Caste/Category")}
                          </Link>
                          <Link to="/tables-editable" className="dropdown-item">
                            {this.props.t("Region")}
                          </Link>
                          <Link to="/tables-dragndrop" className="dropdown-item">
                            {this.props.t("Occupation")}
                          </Link>
                          <Link to="/form-wizard" className="dropdown-item">
                            {this.props.t("Source")}
                          </Link>
                          <Link to="/form-mask" className="dropdown-item">
                            {this.props.t("Employee Status")}
                          </Link>
                          <Link to="/dual-listbox" className="dropdown-item">
                            {this.props.t("Interview Status")}
                          </Link>
                          <Link
                            to="/kc-email-inbox"
                            className="dropdown-item"
                          >
                            {this.props.t("Department")}
                          </Link>
                          <Link to="/kc-detail" className="dropdown-item">
                            {this.props.t("Designation")}
                          </Link>
                          <Link to="/kc-email" className="dropdown-item">
                            {this.props.t("Employment Level")}
                          </Link>
                          <Link to="/kc-one" className="dropdown-item">
                            {this.props.t("Salary Head Master")}
                          </Link>
                          <Link to="/kc-two" className="dropdown-item">
                            {this.props.t("Company Salary Scale Master")}
                          </Link>
                          <Link to="/kc-three" className="dropdown-item">
                            {this.props.t("Company Leave Master")}
                          </Link>
                          <Link to="/kc-four" className="dropdown-item">
                            {this.props.t("Holiday Master")}
                          </Link>
                          <Link
                            to="/kc-five"
                            className="dropdown-item"
                          >
                            {this.props.t("Shift Master")}
                          </Link>
                          <Link
                            to="/kc-six"
                            className="dropdown-item"
                          >
                            {this.props.t("Employee Admin User")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              chartState: !this.state.chartState,
                            });
                          }}
                        >
                          {this.props.t("Retail/Corporate")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.chartState,
                          })}
                        >
                          <Link to="/apex-charts" className="dropdown-item">
                            {" "}
                            {this.props.t("Service")}
                          </Link>
                          <Link to="/chartist-charts" className="dropdown-item">
                            {" "}
                            {this.props.t("Stages")}
                          </Link>
                          <Link to="/e-charts" className="dropdown-item">
                            {" "}
                            {this.props.t("Issues")}
                          </Link>
                          <Link to="/sparkline-charts" className="dropdown-item">
                            {" "}
                            {this.props.t("Channel Partner")}
                          </Link>
                          <Link to="/charts-knob" className="dropdown-item">
                            {this.props.t("Followup Priority")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ iconState: !this.state.iconState });
                          }}
                        >
                          {this.props.t("Accounts")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.iconState,
                          })}
                        >
                          <Link to="/icons-boxicons" className="dropdown-item">
                            {this.props.t("Account Type")}
                          </Link>
                          <Link
                            to="/icons-materialdesign"
                            className="dropdown-item"
                          >
                            {this.props.t("Bank")}
                          </Link>
                          <Link to="/icons-dripicons" className="dropdown-item">
                            {this.props.t("Gst Slab")}
                          </Link>
                          <Link
                            to="/icons-fontawesome"
                            className="dropdown-item"
                          >
                            {this.props.t("HSN Code Master")}{" "}
                          </Link>
                            <Link to="/re-charts" className="dropdown-item">
                            {this.props.t("Payment Mode")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ extraState: !this.state.extraState });
                      }}
                    >
                      <i className="bx bx-file me-2" />
                      {this.props.t("Extra pages")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.extraState,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              invoiceState: !this.state.invoiceState,
                            });
                          }}
                        >
                          {this.props.t("Invoices")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.invoiceState,
                          })}
                        >
                        
                          
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ authState: !this.state.authState });
                          }}
                        >
                          {this.props.t("Authentication")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.authState,
                          })}
                        >
                          
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              utilityState: !this.state.utilityState,
                            });
                          }}
                        >
                          {this.props.t("Utility")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.utilityState,
                          })}
                        >
                          <Link to="/pages-starter" className="dropdown-item">
                            {this.props.t("Starter Page")}
                          </Link>
                          <Link
                            to="/pages-maintenance"
                            className="dropdown-item"
                          >
                            {this.props.t("Maintenance")}
                          </Link>
                          <Link to="/pages-comingsoon" className="dropdown-item">
                            {this.props.t("Coming Soon")}
                          </Link>
                          <Link to="/pages-timeline" className="dropdown-item">
                            {this.props.t("Timeline")}
                          </Link>
                          <Link to="/pages-faqs" className="dropdown-item">
                            {this.props.t("FAQs")}
                          </Link>
                          <Link to="/pages-pricing" className="dropdown-item">
                            {this.props.t("Pricing")}
                          </Link>
                          <Link to="/pages-404" className="dropdown-item">
                            {this.props.t("Error 404")}
                          </Link>
                          <Link to="/pages-500" className="dropdown-item">
                            {this.props.t("Error 500")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
