import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commheader from "./header";
import Commfooter from "./footer";

import bestsuited from "../../assets/images/bestsuited.png";
import handsvedio from "../../assets/images/handsvedio.png";
import whoweareimg from "../../assets/images/whoweareimg.png";
import usline from "../../assets/images/usline.png";
import bestsecurity from "../../assets/images/bestsecurity.png";
import savecredit from "../../assets/images/savecredit.png";
import protect from "../../assets/images/protect.png";
import analytics from "../../assets/images/analytics.png";
import experience from "../../assets/images/experience.png";
import bank from "../../assets/images/bank.png";
import clock from "../../assets/images/clock.png";
import costumer from "../../assets/images/costumer.png";
import nounquote4963139 from "../../assets/images/noun-quote-4963139.png";
import eldrigde from "../../assets/images/eldrigde.png";
import Slider from "react-slick";
import ServiceInquiry from "./serviceinquiry";


const aboutus = () => {
    const { addToast } = useToasts();
    const [TestimonialList, setTestimonialList] = useState([]);
    useEffect(() => {
        getTestimonialListWeb();
    }, []);

    const getTestimonialListWeb = async () => {
        const Testimonialitem = {
            Flag: 'A'
        };
        axios.post(global.APIURL + "/TestimonialList", Testimonialitem)
            .then(Dres => {
                setTestimonialList(Dres.data);
            });
    };
    const settings = {
        dots: true,
        infinite: true,
    
        autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false
            
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            }
          }
        ],
        appendDots: dots => (
          <div
            style={{
              backgroundColor: "#ddd",
              borderRadius: "10px",
              padding: "10px"
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div
            style={{
              width: "30px",
              color: "blue",
              border: "1px blue solid"
            }}
          >
            {i + 1}
          </div>
        )
      };
    return (
        <React.Fragment>
            <Commheader />

            <section className="banner-about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="aboutbannercontent">
                                <h1>One-stop platform for all your credit needs</h1>
                                <p>We are an organization with extensive expertise in financial management. We can give you reliable, accurate credit reports, assist you to establish or strengthen your credit, and aid in better money management.</p>

                            </div>
                            <div className="d-flex mb-2">
                                <div className="bestsuitedimg3">
                                    <img src={bestsuited} alt="" />
                                </div>
                                <div className="aboutbanerrighttext">
                                    <p>Analyses of the credit report in depth</p>
                                </div>
                            </div>
                            <div className="d-flex mb-2">
                                <div className="bestsuitedimg2">
                                    <img src={bestsuited} alt="" />
                                </div>
                                <div className="aboutbanerrighttext">
                                    <p>A variety of concepts, tactics, and problem-solving methods to enhance CMR.</p>
                                </div>
                            </div>
                            <div className="d-flex ">
                                <div className="bestsuitedimg">
                                    <img src={bestsuited} alt="" />
                                </div>
                                <div className="aboutbanerrighttext">
                                    <p>Personalized credit expert to guide you through the procedure and simplify the tasks.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="bannervedio">
                                <a data-fancybox="video-gallery" href="https://www.youtube.com/watch?v=VtLHngErlEM" >
                                    <img src={handsvedio} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoweare">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="whoweareimagesleft">
                                <a href="https://youtu.be/ke7YzZV9pPM" target="_blank" rel="noopener noreferrer">
                                    <img src={whoweareimg} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="whowearecontent">
                                <div className="whowearetitlle">
                                    <h2>Who We Are</h2>
                                    <img src={usline} alt="" className="whoweareline" />
                                </div>
                                <h3> One-stop platform for all your credit needs.</h3>
                                <p>KC Finserv is led by a highly energetic workforce in a transparent environment with our customers. We are focused on the customer and operate with enthusiasm. Our subject experience enables us to share our knowledge and educate our customers, and we are continually striving to invent and simplify solutions for them.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ourmission">
                <div className="container">
                    <div className="ourmissiontittle">
                        <h2>Our Mission & Vision</h2>
                        <p>Eligendi pariatur quos et reiciendis omnis quia modi.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xl-4">
                            <div className="ourmissionboxcontent">
                                <div>
                                    <img src={bestsecurity} alt="" className="bestsecuirityimg" />
                                </div>
                                <div className="ourmissionboxcontentsecuirity">
                                    <h4>Best Security</h4>
                                    <p>While dealing with credit issues, we safeguard you from online identity fraud and identity theft and provide the best security.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="ourmissionboxcontent">
                                <div>
                                    <img src={savecredit} alt="" className="bestsecuirityimg" />
                                </div>
                                <div className="ourmissionboxcontentsecuirity">
                                    <h4>Save Credit</h4>
                                    <p>We improve your credibility while making improvements in your credit score, credit report, and other credit activities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="ourmissionboxcontent newlastbox">
                                <div>
                                    <img src={protect} alt="" className="bestsecuirityimg" />
                                </div>
                                <div className="ourmissionboxcontentsecuirity">
                                    <h4>Protect Customer</h4>
                                    <p>We always have a back for our customers and protect them from theft or fraud.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="analytics">
                <div className="container">
                    <div className="businessanalyticsadd">
                        <img src={analytics} alt="" />
                    </div>
                    <div className="analyticscontent">
                        <h2>Analytics For Our Business</h2>
                        <p>1000+ Members & Associate Professional Members and 50,000+ Hours of Training </p>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="analyticsboxcontent">
                                <img src={experience} alt="" />
                                <h3>20+</h3>
                                <p>Years Experience</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="analyticsboxcontent">
                                <img src={bank} alt="" className="associate-banks" />
                                <h3>90 +</h3>
                                <p>Associated Banks</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="analyticsboxcontent">
                                <img src={clock} alt="" />
                                <h3>50,000+</h3>
                                <p>Hours of Training</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="analyticsboxcontent">
                                <img src={costumer} alt="" />
                                <h3>1,00,000 + </h3>
                                <p>Satisfied Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonialkc">
                <div className="container">
                    <div className="srevices-content">
                        <h2>Testimonial</h2>
                        <p>Our Foundation Successfully working 108 Branches PAN India.</p>
                    </div>
                    <div className="mainslidertesti newslider row">
                        <Slider {...settings}>
                            {TestimonialList.map((item, idx) => (
                                <div className="col-md-4" key={idx}>
                                    <div className="testimonailboxcontent">
                                        <img src={nounquote4963139} alt="" className="testiquoteimages" />
                                        <div className="testimonialtextdot" dangerouslySetInnerHTML={{ __html: item.TestimonialMessage }} />
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img src={item.TestimoniaPersonImage == '' ? eldrigde : item.TestimoniaPersonImage} alt="" className="eldridgeimages" />
                                            <div className="eldridgeerdman">
                                                <h5>{item.TestimonialName}</h5>
                                                <p>{item.TestimonialDesignation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>

            <ServiceInquiry/>

            <Commfooter />

        </React.Fragment>
    )
}
export default aboutus
