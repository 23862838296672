import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commheader from "./header";
import Commfooter from "./footer";

import indiaexellenceimg from "../../assets/images/indiaexellenceimg.png";
import indiaimg1 from "../../assets/images/indiaimg1.png";
import indiaimg2 from "../../assets/images/indiaimg2.png";
import indiaimg3 from "../../assets/images/indiaimg3.png";
import acprovideline from "../../assets/images/provideline.png";
import bestsecurity from "../../assets/images/bestsecurity.png";
import savecredit from "../../assets/images/savecredit.png";
import protect from "../../assets/images/protect.png";
import nounworkexperience1937322 from "../../assets/images/noun-work-experience-1937322.png";
import nounbank2303716 from "../../assets/images/noun-bank-2303716.png";
import nountime5116075 from "../../assets/images/noun-time-5116075.png";
import nounclient4716587 from "../../assets/images/noun-client-4716587.png";
import getintouch from "../../assets/images/getintouch.png";

const achivement = () => {
    const { addToast } = useToasts();
    const [AchivementList, setAchivementList] = useState([]);

    useEffect(() => {
        getAchivementMstListWeb();
    }, []);

    const getAchivementMstListWeb = async () => {
        axios.post(global.APIURL + "/AchivementMstListWeb")
            .then(Dres => {
                setAchivementList(Dres.data);
            });
    };

    return (
        <React.Fragment>
            <Commheader />
            {AchivementList.map((item, idx) => (
                item.NoType == 'O' ?
                    <section className="india-exellence indiabg1" key={idx}>
                        <div className="container newexellenceadd">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-xl-7">
                                    <div className="indiaexellenceimages">
                                        <img src={item.AchivementFilePath} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-5">
                                    <div className="india-exellence-boxcontent">
                                        <span>{item.AchivementYear}</span>
                                        <h3>{item.AchivementTitle}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: item.AchivementDescription }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="india-exellence" key={idx}>
                        <div className="container newexellenceadd2">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-xl-5 order-2 order-md-1">
                                    <div className="india-exellence-boxcontent boxcontentindia">
                                        <span>{item.AchivementYear}</span>
                                        <h3>{item.AchivementTitle}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: item.AchivementDescription }} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-7 order-1 order-md-2">
                                    <div className="indiaexellenceimages">
                                        <img src={item.AchivementFilePath} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            ))}

            <Commfooter />

        </React.Fragment>
    )

}

export default achivement
