import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';

import KCFSLOGOfooter from "../../assets/images/KCFS LOGOfooter.png";
import Linecompany from "../../assets/images/Linecompany.png";
import getintouch from "../../assets/images/getintouch.png";

const footer = () => {
    const { addToast } = useToasts();
    const [CName, setCName] = useState("");
    const [CLogo, setCLogo] = useState("");
    const [CEmailAddress, setCEmailAddress] = useState("");
    const [CContactNumber, setCContactNumber] = useState("");
    const [CAddress, setCAddress] = useState("");

    const [facebookLink, setfacebookLink] = useState("");
    const [InstagramLink, setInstagramLink] = useState("");
    const [LinkedinLink, setLinkedinLink] = useState("");
    const [WhatsappLink, setWhatsappLink] = useState("");

    const [NEmailAddress, setNEmailAddress] = useState("");

    useEffect(() => {
        getContactInformation();
    }, []);
    const getContactInformation = async () => {
        axios.post(global.APIURL + "/ContactInformationDetail")
            .then(Dres => {
                var CIArr = Dres.data;
                if (CIArr != '') {
                    setCName(CIArr[0].CName);
                    setCLogo(CIArr[0].CLogo);
                    setCEmailAddress(CIArr[0].CEmailAddress);
                    setCContactNumber(CIArr[0].CContactNumber);
                    setCAddress(CIArr[0].CAddress);
                    setfacebookLink(CIArr[0].facebookLink);
                    setInstagramLink(CIArr[0].InstagramLink);
                    setLinkedinLink(CIArr[0].LinkedinLink);
                    setWhatsappLink(CIArr[0].WhatsappLink);
                }
                else {
                    setCName('');
                    setCLogo('');
                    setCEmailAddress('');
                    setCContactNumber('');
                    setCAddress('');
                    setfacebookLink('');
                    setInstagramLink('');
                    setLinkedinLink('');
                    setWhatsappLink('');
                }
            });
    };

    const SaveNewsletterInqiry = async () => {
        let IsInsert = 1;
        
        if (NEmailAddress == '') {
            IsInsert = 0;
            alert("Please input email address.");
            return;
        }

        if (IsInsert == 1) {
            const NewsletterInquiry = {
                EmailAddress: NEmailAddress
            };
            axios.post(global.APIURL + "/NewsletterInquirySave", NewsletterInquiry)
                .then(res => {
                    setNEmailAddress('');
                    $("#txtNewsletterEmail").val('');
                    alert("Inquiry save successfully.");
                });
        }
    };

    return (
        <React.Fragment>

            <section className="getintouch">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="getintouchcontent">
                                <h2>Get In Touch</h2>
                                <p>If you have any questions, please feel free to drop a line. </p>
                                <div className="getinleftimages">
                                    <img src={getintouch} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="getintouchinput">
                                <input type="text" name="" id="txtNewsletterEmail" placeholder="Enter Your Email Address" onChange={e => setNEmailAddress(e.target.value)} />
                                <div>
                                    <button type="button" className="btn btn-secondary newsubmitbtn" onClick={() => SaveNewsletterInqiry()}>Submit </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-8">
                            <div>
                                <img src={KCFSLOGOfooter} alt="" className="footerlogo" />
                                <h5 className="footercontentcompany">Connect With Us!</h5>
                                <p className="footerdolore">We love to help customers who have a bad credit scores.<br/>We are a one-stop solution for all of your credit needs.</p>
                                <div className="footericon">
                                    <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                    <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                    <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                    <a href={WhatsappLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4">
                            <div className="footerboxcompany">
                                <img src={Linecompany} alt="" className="linefootercompany" />
                                <h5 className="footercontentcompany">Company</h5>
                                <ul className="footernavcontentadd">
                                    <li><a href="aboutus">About Us </a></li>
                                    <li><a href="termsCondition">Terms & Condition</a></li>
                                    <li><a href="privacypolicy"> Privacy Policy</a></li>
                                    <li><a href="achivement"> Achievement</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-8 newfootercontact">
                            <div className="footerboxcompany">
                                <img src={Linecompany} alt="" className="linefootercompany2" />
                                <h5 className="footercontentcompany">Contact Us</h5>
                                <ul className="footernavcontentadd">
                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-map-marker-alt mapmarker"></i>
                                        <li><p className="footertextwhite">{CAddress}</p></li>
                                    </div>
                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-envelope mapmarker"></i>
                                        <li><p className="footertextwhite">{CEmailAddress}</p></li>
                                    </div>
                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-phone mapmarker"></i>
                                        <li><p className="footertextwhite">{CContactNumber}</p></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-4 newfootercontact">
                            <div className="footerboxcompany">
                                <img src={Linecompany} alt="" className="linefootercompany3" />
                                <h5 className="footercontentcompany">Our Offerings</h5>
                                <ul className="footernavcontentadd">
                                    <li><a href=""> Loan</a></li>
                                    <li><a href=""> Credit Card</a></li>
                                    <li><a href="">Personal Loan</a></li>
                                    <li><a href=""> Home Loan</a></li>
                                    <li><a href="">Auto Loan</a></li>
                                    <li><a href="">Gold Loan</a></li>
                                    <li><a href="">Education Loan</a></li>
                                    <li><a href="">Two Wheeler Loan</a></li>
                                    <li><a href="">Loan Against Property</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )

}

export default footer
