import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Input, img } from "reactstrap"
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import LogoImage from "../../assets/images/KCFS LOGO.png";
import bottal from "../../assets/images/bottal.png";
import bindu from "../../assets/images/bindu.png";
import bindu2 from "../../assets/images/bindu2.png";
import creditimg from "../../assets/images/creditimg.png";
import img2 from "../../assets/images/img2.png";
import usline from "../../assets/images/usline.png";
import serviceimg1 from "../../assets/images/serviceimg1.png";
import improveimg1 from "../../assets/images/improveimg1.png";
import improveimg2 from "../../assets/images/improveimg2.png";
import businesspeopleworkingfinanceaccountinganalyzefinanci from "../../assets/images/businesspeople-working-finance-accounting-analyze-financi.png";
import comprehensive from "../../assets/images/comprehensive.png";
import analytics from "../../assets/images/analytics.png";
import experience from "../../assets/images/experience.png";
import bank from "../../assets/images/bank.png";
import clock from "../../assets/images/clock.png";
import costumer from "../../assets/images/costumer.png";
import certificate from "../../assets/images/certificate.png";
import nounquote4963139 from "../../assets/images/noun-quote-4963139.png";
import eldrigde from "../../assets/images/eldrigde.png";
import getintouch from "../../assets/images/getintouch.png";
import KCFSLOGOfooter from "../../assets/images/KCFS LOGOfooter.png";
import Linecompany from "../../assets/images/Linecompany.png";
import Commheader from "./header";
import Commfooter from "./footer";
import Slider from "react-slick";
import ServiceInquiry from "./serviceinquiry";

const home = () => {
  const { addToast } = useToasts();
  const [TestimonialList, setTestimonialList] = useState([]);
  const [WebServicesList, setWebServicesList] = useState([]);
  useEffect(() => {
    getTestimonialListWeb();
    getWebServicesList();
  }, []);

  const getTestimonialListWeb = async () => {
    const Testimonialitem = {
      Flag: 'A'
    };
    axios.post(global.APIURL + "/TestimonialList", Testimonialitem)
      .then(Dres => {
        setTestimonialList(Dres.data);
      });
  };

  const getWebServicesList = async () => {

    axios.post(global.APIURL + "/WebServicesListWeb")
      .then(Dres => {
        setWebServicesList(Dres.data);
      });
  };

  const settings = {
    dots: true,
    infinite: true,

    autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ],
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "#ddd",
          borderRadius: "10px",
          padding: "10px"
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: "30px",
          color: "blue",
          border: "1px blue solid"
        }}
      >
        {i + 1}
      </div>
    )
  };
  const settings2 = {
    dots: true,
    infinite: true,
  
    autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ],
   
  };
  return (
    <React.Fragment>
      <Commheader />

      <section className="banner">
        <div className="container">
          <div className="bannersidebottel">
            <img src={bottal} alt="" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="banner-content">
                <h1><span>Credit Repair Business</span> The positive impact on your credit.</h1>
                <p>KC Finserv assists you in determining precisely what is harming your credit score and how we can work to improve it..</p>
                <button type="button" className="btn btn-primary newlearenmorebtn">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="abouts-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 newrightboxaboutus">
              <div className="credirpairimg">
                <div className="bindu">
                  <img src={bindu} alt="" />
                  <input className="form-check-input binduinputradio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                </div>
                <div className="bindu2">
                  <img src={bindu2} alt="" />
                  <input className="form-check-input binduinputradio2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                </div>
              </div>
              <div>
                <div className="abouts-usboxcontet" >
                  <img src={creditimg} alt="" />
                  <p>“Credit Repair Business” <br /> The positive impact on your credit</p>
                </div>
                <div className="abouts-usboxcontet newaboutboxbusiness">
                  <img src={img2} alt="" />
                  <p>KC Finserv assists you in determining precisely what is harming your credit score and how we can work to improve it.</p>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div>
                <div className="newunique">
                  <h2 className="aboutcontentright">About Us</h2>
                  <div className="aboutlineus">
                    <img src={usline} alt="" />
                  </div>
                </div>

                <h5 className="aboutbusinesopurtonity">We Simplify Your Credit Health by Using Your Credit Data, Our Experience, and Technology.</h5>
                <p className="abourparagraphbeatea">Our goal at KC Finserv is to assist you in resolving your financial issues and establishing a solid credit history.</p>
                <p className="abourparagraphbeatea">Along with handling issues with your credit, we will safeguard you from online identity fraud and identity theft.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <div className="srevices-content">
            <h2>Services</h2>
            <p>We are educating to bankers for credit bureaus CIBIL,<br /> Experian, Equifax and CRIF Highmark.</p>
          </div>
          <div className=" mainslidercompany newslider row ">
            <Slider {...settings}>
              {WebServicesList.map((item, idx) => (
                <div className="col-md-4" key={idx}>
                  <div className="serviceboxcontent">
                    <img src={item.IconFile} alt="" />
                    <h4>{item.Title}</h4>
                    <div className="mainserviceboxtext" dangerouslySetInnerHTML={{ __html: item.Description }} />
                   
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      {/* <section className="assocaitepartner">
        <div className="container ">
          <div className="partnetcontent">
            <h2>We are offering you as an <span>ASSOCIATE PARTNER</span> with us.</h2>
          </div>


          <ul className="nav nav-pills " id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                aria-selected="true">Credit Report</button>
            </li>
            <li className="nav-item navitempartener" role="presentation">
              <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                aria-selected="false">Clean Your CIBIL</button>
            </li>
            <li className="nav-item navitempartener" role="presentation">
              <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                aria-selected="false">Approval</button>
            </li>
            <li className="nav-item navitempartener" role="presentation">
              <button className="nav-link" id="pills-cibil-tab" data-bs-toggle="pill" data-bs-target="#pills-cibil"
                type="button" role="tab" aria-controls="pills-cibil" aria-selected="false">Improve
                CIBIL</button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="newpartneroffring">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="parnetrimages">
                      <img src={businesspeopleworkingfinanceaccountinganalyzefinanci} alt="" />
                      <div className="financecompresive">
                        <img src={comprehensive} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="parnerboxcontent">
                      <h4>Comprehensive Analysis of the Credit Report</h4>
                      <p>Aliquam modi magnam. Vitae assumenda eos ex laborum beatae<br /> suscipit ab. Est
                        dolores corporis
                        quo unde et quod tempore omnis.<br /> Non accusantium enim. Necessitatibus perferendis
                        eveniet.
                        Sequi<br /> consequatur distinctio ut eos totam distinctio non et.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div className="newpartneroffring">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="parnetrimages">
                      <img src={businesspeopleworkingfinanceaccountinganalyzefinanci}
                        alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="parnerboxcontent">
                      <h4>Comprehensive Analysis of the Credit Report</h4>
                      <p>Aliquam modi magnam. Vitae assumenda eos ex laborum beatae suscipit ab. Est
                        dolores corporis
                        quo unde et quod tempore omnis. Non accusantium enim. Necessitatibus perferendis
                        eveniet.
                        Sequi consequatur distinctio ut eos totam distinctio non et.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <div className="newpartneroffring">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="parnetrimages">
                      <img src={businesspeopleworkingfinanceaccountinganalyzefinanci}
                        alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="parnerboxcontent">
                      <h4>Comprehensive Analysis of the Credit Report</h4>
                      <p>Aliquam modi magnam. Vitae assumenda eos ex laborum beatae suscipit ab. Est
                        dolores corporis
                        quo unde et quod tempore omnis. Non accusantium enim. Necessitatibus perferendis
                        eveniet.
                        Sequi consequatur distinctio ut eos totam distinctio non et.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-cibil" role="tabpanel" aria-labelledby="pills-cibil-tab">
              <div className="newpartneroffring">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="parnetrimages">
                      <img src={businesspeopleworkingfinanceaccountinganalyzefinanci} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="parnerboxcontent">
                      <h4>Comprehensive Analysis of the Credit Report</h4>
                      <p>Aliquam modi magnam. Vitae assumenda eos ex laborum beatae suscipit ab. Est
                        dolores corporis
                        quo unde et quod tempore omnis. Non accusantium enim. Necessitatibus perferendis
                        eveniet.
                        Sequi consequatur distinctio ut eos totam distinctio non et.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="analytics">
        <div className="container">
          <div className="businessanalyticsadd">
            <img src={analytics} alt="" />
          </div>
          <div className="analyticscontent">
            <div className="newunique">
              <h2>Analytics For Our Business</h2>
              <div className="aboutlineus2">
                <img src={usline} alt="" />
              </div>
            </div>
            <p>1000+ Members & Associate Professional Members and 50,000+ Hours of Training </p>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="analyticsboxcontent">
                <img src={experience} alt="" />
                <h3>20+</h3>
                <p>Years Experience</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analyticsboxcontent">
                <img src={bank} alt="" className="associate-banks" />
                <h3>90 +</h3>
                <p>Associated Banks</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analyticsboxcontent">
                <img src={clock} alt="" />
                <h3>50,000+</h3>
                <p>Hours of Training</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="analyticsboxcontent">
                <img src={costumer} alt="" />
                <h3>1,00,000 + </h3>
                <p>Satisfied Clients</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="awarded">
        <div className="container newawardedadd d-flex align-items-center">
         

          <div className=" row  justify-content-end w-100">
           
            <div className="col-md-6">
            <div className="text-center">
            <h2 className="awardebytittle mb-4">Awarded by</h2>
          </div>
              <div className="mainsliderawared ">
              <Slider {...settings2}>
                <div className="awardebox">
                  <img src={certificate} alt="" />
                  <h5>India Excellence Awards</h5>
                  <p>2019</p>
                </div>


                <div className="awardebox">
                  <img src={certificate} alt="" />
                  <h5>India Excellence Awards</h5>
                  <p>2020</p>
                </div>


                <div className="awardebox">
                  <img src={certificate} alt="" />
                  <h5>India Excellence Awards</h5>
                  <p>2019</p>
                </div>
                </Slider>






              </div>
            </div>

          </div>

        </div>
      </section> */}

      <section className="testimonialkc">
        <div className="container">
          <div className="srevices-content">
            <h2>Testimonial</h2>
            <p>Our Foundation Successfully working 108 Branches PAN India.</p>
          </div>
          <div className="mainslidertesti newslider row">
            <Slider {...settings}>
              {TestimonialList.map((item, idx) => (
                <div className="col-md-4" key={idx}>
                  <div className="testimonailboxcontent">
                    <img src={nounquote4963139} alt="" className="testiquoteimages" />
                    <div className="testimonialtextdot" dangerouslySetInnerHTML={{ __html: item.TestimonialMessage }} />
                    <div className="d-flex align-items-center justify-content-center">
                      <img src={item.TestimoniaPersonImage == '' ? eldrigde : item.TestimoniaPersonImage} alt="" className="eldridgeimages" />
                      <div className="eldridgeerdman">
                        <h5>{item.TestimonialName}</h5>
                        <p>{item.TestimonialDesignation}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <ServiceInquiry/>
      <Commfooter />

    </React.Fragment>
  )

}

export default home
